<template>
	<div>
		<b-modal
			id="modal-form-change-resid"
			ref="modal"
			:title="$t('modal.main_place.title')"
			@show="resetModal"
			@hidden="resetModal"
			@ok="handleOk"
		>
			<form ref="form" @submit.stop.prevent="handleSubmit">
				<b-form-group
					:state="dateState"
					:label="$t('modal.main_place.date_label')"
					label-for="date-input"
					:invalid-feedback="$t('modal.main_place.invalid_date_feedback')"
				>
					<e-datepicker id="date-input" v-model="date" required />
				</b-form-group>

				<b-form-group
					:state="raisonState"
					:label="$t('modal.main_place.mouv_reason')"
					label-for="raison-input"
					:invalid-feedback="$t('modal.main_place.invalid_reason_feedback')"
				>
					<b-form-input
						id="date-input"
						v-model="raison"
						required
					></b-form-input>
				</b-form-group>
			</form>
		</b-modal>
	</div>
</template>

<script>
    export default {
        props: [
            'success_callback'
        ],
        data() {
            return {
                actual_date: null,
                date: null,
                raison: '',
                dateState: null,
                raisonState: null
            }
        },
        mounted() {
            this.actual_date = new Date()
            this.resetModal()
        },
        methods: {
            checkFormValidity() {
                this.dateState = this.date ? true : false
                this.raisonState = this.raison ? true : false
                return this.dateState && this.raisonState
            },
            resetModal() {
                this.date = this.actual_date
                this.raison = ''
                this.dateState = null
                this.raisonState = null
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
                }
                this.success_callback({ date: this.date, raison: this.raison })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$refs.modal.hide()
                })
            },
            openModal() {
                this.$bvModal.show('modal-form-change-resid')
            }
        }
    }
</script>
